@import "styles/variables";

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.5rem;
  background-color: $WHITE-GREY;
  max-width: 100%;
  min-height: 100vh;

  @include tablet-landscape {
    padding: 0 3.2rem;
  }

  @include desktop {
    padding: 0 8rem;
  }

  .leftBlock {
    display: flex;
    flex-direction: column;
    max-width: 70.6rem;

    .racIcon {
      width: 19rem;
      height: 5rem;
    }

    .textContainer {
      margin: 2rem 0;
      padding: 2rem;
      background-color: $WHITE;
      border-radius: 0.8rem;
      box-shadow: 0 0.3rem 0.6rem 0 rgb(0 0 0 / 16%);

      @include tablet-landscape {
        margin-right: 2rem;
        padding: 4rem;
      }

      .titleContainer {
        display: flex;
        align-items: center;

        .imageColored {
          margin-right: 1.5rem;
        }

        .title {
          color: $GUNMETAL;
          font-size: 2rem;
          font-weight: $FONT-WEIGHT-BOLD;

          @include tablet-landscape {
            font-size: 2.9rem;
          }
        }
      }

      .text {
        margin-top: 3.2rem;
        color: $GUNMETAL;
        font-size: 1.5rem;
      }
    }

    .goBackBtn {
      padding: 1.5rem 4rem;
      color: $WHITE;
      background-color: $OCEAN-BLUE;
      font-size: 1.4rem;
      font-weight: $FONT-WEIGHT-BOLD;
      align-self: flex-end;
      border-radius: 0.4rem;
      max-width: 23rem;
      text-transform: uppercase;

      &:hover {
        background-color: $MARINE-LIGHT;
      }
    }
  }

  .rightImageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
