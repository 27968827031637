@import "styles/variables";

@font-face {
  font-weight: normal;
  font-family: VAGRounded-Bold;
  src: url("/VAGRounded-Bold.ttf") format("truetype");
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  overflow-anchor: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $FONT-WEIGHT-BOLD;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: white;
  font-family: "Open Sans", sans-serif;
  border: none;
  cursor: pointer;
}

ul {
  list-style-type: none;
}

.leaflet-container {
  font-family: "Open Sans", sans-serif !important;
}

.leaflet-popup-content-wrapper {
  padding: 0 !important;
  border-radius: 0 !important;
}

.leaflet-popup-content {
  width: unset !important;
  margin: 0 !important;
}

.leaflet-pane,
.leaflet-top,
.leaflet-bottom {
  z-index: 0 !important;
}

/* stylelint-disable-next-line selector-id-pattern */
#pl-pm-cards_4-cardNumber,
#pl-pm-cards_4-cvv {
  padding-left: 0 !important;

  &:focus {
    box-shadow: none !important;
  }

  &::placeholder {
    color: $BLUE-GREY !important;
  }
}

.PaylineWidget {
  font-family: "Open Sans", sans-serif !important;

  .pl-container-default {
    .pl-pmContainer {
      margin: 0 !important;
    }

    .pl-paymentMethodLayout-view {
      .pl-pmContainer {
        padding: 0 !important;
      }
    }
  }

  .pl-paymentMethod-view {
    padding: 0 !important;
  }

  .pl-form-control-addon {
    display: none !important;
  }

  .pl-input {
    padding-left: 1.3rem !important;
    font-size: 1.2rem !important;
    font-family: "Open Sans", sans-serif !important;
    background: transparent !important;
    border: 0.1rem solid $LIGHT-PERIWINKLE !important;
    border-radius: 0.4rem !important;
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }

    &::placeholder {
      color: $LIGHT-PERIWINKLE !important;
    }
  }

  .pl-label-input {
    width: 100% !important;
    margin-bottom: 0 !important;
    color: $GUNMETAL !important;
    font-size: 1.5rem !important;
    font-weight: normal !important;
    font-family: "Open Sans", sans-serif !important;
    line-height: 2.4rem !important;
    text-align: left !important;
  }
}

.pl-pay-btn {
  &:hover {
    background: $MARINE-LIGHT !important;
  }

  &:disabled {
    color: $BLUEY-GREY;
    opacity: 1;
    cursor: not-allowed;
  }

  margin: 0 !important;
  padding: 1.5rem !important;
  font-size: 1.5rem !important;
  font-weight: $FONT-WEIGHT-BOLD !important;
  font-family: "Open Sans", sans-serif !important;
  min-width: 100% !important;
  background: $OCEAN-BLUE !important;
  line-height: 2rem !important;
  text-transform: uppercase !important;

  @include tablet-landscape {
    min-width: 15rem !important;
  }
}

.pl-card-logos-container {
  display: none;
}

.pl-pay-btn-container {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;

  @include tablet-landscape {
    justify-content: flex-end !important;
  }
}

.PaylineWidget.pl-container-default .pl-pmContainer {
  padding: 0.1rem !important;
  background-color: $WHITE !important;
  border: none !important;
}

.pl-expirationDateContainer {
  width: 40% !important;
  float: left !important;
}

.cgl-block {
  margin: 1.8rem 0 !important;
  color: $GUNMETAL;
  font-size: 1.2rem !important;
  text-align: left !important;
  line-height: 2.4rem !important;

  a {
    font-size: 1.2rem !important;
    line-height: 2.4rem !important;
  }
}

.pl-cvvContainer {
  width: 40% !important;
  float: right !important;
}
